import reject from 'lodash.reject'

import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filtersStore', () => {
  const routeName = ref(null)
  const active = ref([])
  const shouldListen = ref(false)
  const expandedFilter = ref(null)
  const lastFilterToggled = ref(null) // For GA tracking event

  const getRouteName = computed(() => routeName.value)
  const items = computed(() => active.value)
  const getShouldListen = computed(() => shouldListen.value)
  const displayableFilters = computed(() => active.value.filter(f => f.display))
  const getLastFilterToggled = computed(() => lastFilterToggled.value)

  const filterByType = type => active.value.filter(f => f.type === type)
  const isActive = filter => {
    const match = active.value.find(
      f => f.type === filter.type && f.value === filter.value && (f.value !== null || f.value !== undefined)
    )
    return !!match
  }

  const setRouteName = value => {
    routeName.value = value
  }
  const setShouldListen = listen => {
    shouldListen.value = listen
  }
  const setActiveFilters = value => {
    active.value = value
  }
  const addFilter = ({ filter, shouldListen: value = true }) => {
    shouldListen.value = value
    active.value.push(filter)
    lastFilterToggled.value = filter
  }
  const addFilterUnique = filter => {
    active.value = reject(active.value, f => f.type === filter.type)
    shouldListen.value = true
    active.value.push(filter)
    lastFilterToggled.value = filter
  }
  const addFiltersUnique = filters => {
    for (let i = 0; i <= filters.length - 2; i += 1) {
      const filter = filters[i]
      active.value = reject(active.value, f => f.type === filter.type)
      active.value.push(filter)
    }
    const filter = filters[filters.length - 1]
    active.value = reject(active.value, f => f.type === filter.type)
    shouldListen.value = true
    active.value.push(filter)
  }
  const removeFilter = filter => {
    shouldListen.value = true
    active.value = reject(active.value, f => f.type === filter.type && f.value === filter.value)
  }
  const removeFilters = () => {
    shouldListen.value = true
    active.value = []
  }
  const removeFilterTypes = ({ types, shouldListen: value }) => {
    shouldListen.value = value
    active.value = reject(active.value, f => types.includes(f.type))
  }
  const setExpandableFilter = filter => {
    expandedFilter.value = filter
  }

  return {
    routeName,
    active,
    shouldListen,
    expandedFilter,
    lastFilterToggled,
    getRouteName,
    items,
    getShouldListen,
    displayableFilters,
    getLastFilterToggled,
    filterByType,
    isActive,
    setRouteName,
    setShouldListen,
    setActiveFilters,
    addFilter,
    addFilterUnique,
    addFiltersUnique,
    removeFilter,
    removeFilters,
    removeFilterTypes,
    setExpandableFilter,
  }
})
